import React, { useEffect, useState } from "react";

import Select from "../UI/Select";
import Input from "../UI/Input";
import Button from "../UI/Button";

import { useForm } from "../Hooks/form-hook";
import { useHttpClient } from "../Hooks/http-hook";

import {
  VALIDATOR_NUMBERS,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
} from "../UI/util/validators";

import styles from "./CCP.module.css";

const CCP = (props) => {
  const [counter, setCounter] = useState(0);
  const [timer, setTimer] = useState();

  const { isLoading, sendRequest } = useHttpClient();
  const [queueOptions, setQueueOptions] = useState([
    {
      value: "",
      name: "--Please Select--",
    },
  ]);

  const [countryOptions, setCountryOptions] = useState([
    {
      value: "+1",
      name: "+1 (US/Canada)",
    },
  ]);

  const [formState, inputHandler, selectHandler] = useForm(
    {
      queue: {
        value: "",
        isValid: false,
      },
      country: {
        value: "+1",
        isValid: true,
      },
      phonenumber: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    setQueueOptions(props.agentQueues);
  }, [props.agentQueues]);

  useEffect(() => {
    console.log(`Country Code ====> ${formState.inputs.country.value}`);
    inputHandler("phonenumber", "", false);
  }, [formState.inputs.country.value]);

  //   console.log(`Agent State [CCP] ===> ${JSON.stringify(props.agentInfo)}`);
  //   console.log(
  //     `Agent State [CCP] ===> ${JSON.stringify(props.agentInfo?.agentState)}`
  //   );
  //   console.log(`Agent State [CCP] ===> ${props.agentInfo?.agentState?.name}`);

  useEffect(() => {
    if (!timer && props.agentInfo?.agentState?.name === "Busy") {
      setTimer(
        setInterval(() => {
          setCounter((counter) => counter + 1);
        }, 1000)
      );
    }

    if (timer && props.agentInfo?.agentState?.name === "AfterCallWork") {
      clearInterval(timer);
      setTimer();
      setCounter(0);
    }
  }, [props.agentInfo, counter, timer]);

  useEffect(() => {
    const processExe = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_AWS_ENDPOINT}/list-outboundcountries`
        );
        responseData.message.sort((a, b) => {
          return a.countrycode - b.countrycode;
        });

        let countries = responseData.message.map((x) => {
          return {
            name: x.name,
            value: x.countrycode,
          };
        });
        setCountryOptions(countries);
      } catch (error) {
        console.error(`Fetch Countries [Error] ====> ${error}`);
        setCountryOptions([
          {
            value: "+1",
            name: "+1 (US/Canada)",
          },
        ]);
      }
    };
    processExe();
  }, [sendRequest]);

  const makeOutboundCallHandler = (event) => {
    event.preventDefault();
    props.makeOutboundCall(
      formState.inputs.country.value,
      formState.inputs.phonenumber.value,
      formState.inputs.queue.value
    );
    selectHandler("queue", "", false);
    selectHandler("country", "+1", true);
    inputHandler("phonenumber", "", false);
  };

  let min =
    Math.floor(counter / 60) < 10
      ? `0${Math.floor(counter / 60)}`
      : Math.floor(counter / 60);
  let sec = counter % 60 < 10 ? `0${counter % 60}` : counter % 60;

  return (
    <div className={`${styles.ccp_Container}`}>
      <div className={`${styles.ccp_AgentPanel}`}>
        {props.outboundFeature && (
          <fieldset className={`${styles.ccp_Outbound}`}>
            <legend>
              <h1>OUTBOUND CALL</h1>
            </legend>

            <form
              className={`${styles.ccp_Queues}`}
              onSubmit={makeOutboundCallHandler}>
              <div className={`${styles.ccp_Field}`}>
                <Select
                  id="queue"
                  label="Outbound Queue"
                  options={queueOptions}
                  onSelect={selectHandler}
                  value={formState.inputs.queue.value}
                  valid={formState.inputs.queue.isValid}
                />
              </div>
              <div className={`${styles.ccp_Field}`}>
                <Select
                  id="country"
                  label="Country Code"
                  options={countryOptions}
                  onSelect={selectHandler}
                  phonenumber={formState.inputs.phonenumber.value}
                  value={formState.inputs.country.value}
                  valid={formState.inputs.country.isValid}
                />
                <Input
                  id="phonenumber"
                  label="Phone Number"
                  element="input"
                  type="text"
                  validators={
                    formState.inputs.country.value === "+1"
                      ? [
                          VALIDATOR_NUMBERS,
                          VALIDATOR_MINLENGTH(10),
                          VALIDATOR_MAXLENGTH(10),
                        ]
                      : [VALIDATOR_NUMBERS, VALIDATOR_MINLENGTH(1)]
                  }
                  onInput={inputHandler}
                  countrycode={formState.inputs.country.value}
                  isDisabled={!formState.inputs.queue.isValid}
                  value={formState.inputs.phonenumber.value}
                  valid={formState.inputs.phonenumber.isValid}
                />
              </div>

              <div className={`${styles.ccp_CallButton}`}>
                <Button
                  type="submit"
                  disabled={
                    !formState.isValid || !formState.inputs.phonenumber.isValid
                  }>
                  CALL
                </Button>
              </div>
            </form>
          </fieldset>
        )}

        <fieldset className={`${styles.ccp_Info}`}>
          <legend>
            <h1>INFORMATION</h1>
          </legend>

          {props.agentInfo && props.agentInfo?.agentState?.name === "Busy" && (
            <div className={`${styles.cpp__Section__Timer}`}>
              <h5>On Contact:</h5>
              <h6>
                {min}:{sec}
              </h6>
            </div>
          )}

          {(props.queue || props.contactAttributes) && (
            <div className={`${styles.cpp__Section__Header}`}>
              <h1>
                <img src="images/phone.svg" alt="phone" />
                CALL
              </h1>
            </div>
          )}
          {props.queue && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Queue Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>{props.queue ? props.queue : "Unavailable"}</h6>
              </div>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Customer Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.client_name
                    ? props.contactAttributes.client_name
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Caller Phone Number</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.callback_number
                    ? props.contactAttributes.callback_number.replace("+1", "")
                    : props.contactAttributes.customer_number
                    ? props.contactAttributes.customer_number.replace("+1", "")
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.cpp__Section__Header}`}>
              <h1>
                <img
                  src="images/person.svg"
                  style={{ height: "1.2rem" }}
                  alt="person"
                />
                CUSTOMER
              </h1>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Name</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                {!props.contactAttributes.first_name &&
                  props.contactAttributes.last_name && (
                    <h6>{props.contactAttributes.last_name}</h6>
                  )}
                {props.contactAttributes.first_name &&
                  !props.contactAttributes.last_name && (
                    <h6>{props.contactAttributes.first_name}</h6>
                  )}
                {!props.contactAttributes.first_name &&
                  !props.contactAttributes.last_name && <h6>Unavailable</h6>}
                {props.contactAttributes.first_name &&
                  props.contactAttributes.last_name && (
                    <h6>
                      {props.contactAttributes.first_name}&nbsp;
                      {props.contactAttributes.last_name}
                    </h6>
                  )}
              </div>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Title</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.title
                    ? props.contactAttributes.title
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {props.contactAttributes && (
            <div className={`${styles.ccp_Info__Segment}`}>
              <div className={`${styles.ccp_Info__Segment__Header}`}>
                <h5>Specialty Group</h5>
              </div>
              <div className={`${styles.ccp_Info__Segment__Value}`}>
                <h6>
                  {props.contactAttributes.u_specialty_group
                    ? props.contactAttributes.u_specialty_group
                    : "Unavailable"}
                </h6>
              </div>
            </div>
          )}
          {!props.queue && !props.contactAttributes && (
            <div className={`${styles.ccp_NoInfo}`}>
              <h3>No Data</h3>
              <h4>Waiting for Incoming Call....</h4>
            </div>
          )}
        </fieldset>
      </div>
      <div className={`${styles.ccp_CustomerJourney}`}>
        <h1>Customer Journey</h1>
      </div>
    </div>
  );
};

export default CCP;
