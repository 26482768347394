import React from 'react'

import styles from './Dashboard.module.css'

const Dashboard = (props) => {
    return (<div className={`${styles.dashboard__Container}`}>
        <div className={`${styles.dashboard__Header}`}>
            <h1>Dashboard</h1>
        </div>
        <div className={`${styles.dashboard__Stats}`}>

        </div>
    </div>)
}

export default Dashboard