import React, { useReducer, useEffect } from "react";

import { validate } from "../UI/util/validators";
import "./Input.css";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH": {
      return {
        ...state,
        isTouched: true,
      };
    }
    default:
      return state;
  }
};

const Input = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.value || "",
    isTouched: false,
    isValid: props.valid || false,
  });

  const { id, onInput, onTyped, countrycode } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    console.log(`Input ====> ${event.target.value.length}`);
    console.log(`CountryCode ====> ${countrycode}`);
    const pointer = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;
    });

    if (countrycode === "+1") {
      if (event.target.value.length <= 10) {
        dispatch({
          type: "CHANGE",
          val: event.target.value,
          validators: props.validators,
        });
      } else {
        dispatch({
          type: "CHANGE",
          val: event.target.value.substr(0, 10),
          validators: props.validators,
        });
      }
    } else {
      dispatch({
        type: "CHANGE",
        val: event.target.value,
        validators: props.validators,
      });
    }

    if (onTyped) {
      onTyped(event.target.value);
    }
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  let element = (
    <textarea
      id={props.id}
      rows={props.rows || 3}
      cols={props.cols || 50}
      onChange={changeHandler}
      onBlur={touchHandler}
      value={props.value}
      disabled={props.isDisabled}
    />
  );

  if (props.element === "input") {
    element = (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={props.value}
        disabled={props.isDisabled}
      />
    );
  }

  return (
    <div
      className={`form-control ${props.classStyle} ${
        props.isDisabled && "form-control--disabled"
      } ${props.isHighlighted && "form-control--highlight"}`}>
      <label htmlFor={props.id}>{props.label}</label>
      {element}
    </div>
  );
};

export default Input;
