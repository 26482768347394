import React, { useEffect } from "react";
import "amazon-connect-streams";
import moment from "moment-timezone";

import styles from "./CCPPanel.module.css";

const CCPPanel = (props) => {
  useEffect(() => {
    console.log(
      `[CCP: ${moment
        .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        .format("YYYY-MM-DD HH:mm:ss")}] =====> Loading CCP`
    );

    // eslint-disable-next-line no-undef
    connect.core.initCCP(document.getElementById("ccp_panel"), {
      ccpUrl: `https://eusprodconnect.my.connect.aws/connect/ccp-v2`,
      loginPopup: true,
      loginPopupAutoClose: true,
      loginOptions: {
        autoClose: true,
      },
      region: "us-east-1",
      softphone: {
        allowFramedSoftphone: true,
        disableRingtone: false,
      },
      pageOptions: {
        enableAudioDeviceSettings: true,
        enablePhoneTypeSettings: false,
      },
      ccpAckTimeout: 5000,
      ccpSynTimeout: 3000,
      ccpLoadTimeout: 10000,
    });

    // eslint-disable-next-line no-undef
    connect.core.onInitialized(() => {
      console.log(
        `[CCP - On Initialized: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}]=====> Connect Initialized`
      );

      props.updateOutboundFeature(true);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthorizeSuccess(() => {
      console.log(
        `[CCP - On Authorize Success: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Authorized`
      );
      props.authUpdate(true);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthorizeRetriesExhausted(() => {
      console.log(
        `[CCP - OnAuthorizeRetriesExhausted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}] =====> Connect Authorization Exhaused`
      );
    });

    // eslint-disable-next-line no-undef
    connect.core.onIframeRetriesExhausted(() => {
      console.log(
        `[CCP - OnIFrameRetriesExhauseted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect CCP Reload Exhausted`
      );
    });

    // eslint-disable-next-line no-undef
    connect.core.onAuthFail(() => {
      console.log(
        `[CCP - OnAuthFail: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Authorization Failed`
      );
      props.updateOutboundFeature(false);
    });

    // eslint-disable-next-line no-undef
    connect.core.onAccessDenied(() => {
      console.log(
        `[CCP - OnAccessDenied: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> Connect Access Denied`
      );
    });

    // eslint-disable-next-line no-undef
    connect.core.onCTIAuthorizeRetriesExhausted(() => {
      console.log(
        `[CCP - onCTIAuthorizeRetriesExhausted: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}] =====> Connect CTI Retries Exhausted!`
      );
    });

    if (props.outbound) {
      console.log(
        `[CCP - Outbound Calling: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format(
            "YYYY-MM-DD HH:mm:ss"
          )}] =====>Outbound Object ${JSON.stringify(props.outbound)}`
      );

      // eslint-disable-next-line no-undef
      var endpoint = connect.Endpoint.byPhoneNumber(
        `${props.outbound.country}${props.outbound.phonenumber}`
      );

      // eslint-disable-next-line no-undef
      var agent = new connect.Agent();
      var queueArn = props.outbound.queue;

      agent.connect(endpoint, {
        queueARN: queueArn,
        success: function () {
          console.log(
            `[CCP - onCTIAuthorizeRetriesExhausted: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> Outbound Call Connected!`
          );
          props.makeOutboundCall(undefined, undefined, undefined);
        },
        failure: function (err) {
          console.error(
            `[CCP - onCTIAuthorizeRetriesExhausted: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format(
                "YYYY-MM-DD HH:mm:ss"
              )}] =====> Outbound Call Connection Failed!`
          );
          props.makeOutboundCall(undefined, undefined, undefined);
          props.updateOutboundFeature(false);
          setTimeout(() => {
            props.updateOutboundFeature(true);
          }, 1000);
        },
      });
    }

    // eslint-disable-next-line no-undef
    connect.contact((contact) => {
      contact.onPending((contact) => {
        console.log(
          `[CCP - On Pending: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onRefresh((contact) => {
        console.log(
          `[CCP - On Refresh: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onIncoming((contact) => {
        console.log(
          `[CCP - On Incoming: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onAccepted((contact) => {
        console.log(
          `[CCP - On Accepted: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
        console.log(
          `[CCP - On Accepted: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
            contact.getQueue()
          )}`
        );
        let attributes = contact.getAttributes();

        if (attributes) {
          let newAttributes = {};
          for (let key in attributes) {
            newAttributes[attributes[key].name] = attributes[key].value;
          }
          console.log(
            `[CCP - On Accepted [Contact Attributes]: ${moment
              .tz(new Date(), process.env.REACT_APP_TIMEZONE)
              .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
              newAttributes
            )}`
          );
          props.contactAttributesUpdate(newAttributes);
          props.queueUpdate(contact.getQueue().name);
        }
      });
      contact.onConnecting((contact) => {
        console.log(
          `[CCP - On Connecting: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
        props.updateOutboundFeature(false);
      });
      contact.onConnected((contact) => {
        console.log(
          `[CCP - On Connected: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onEnded((contact) => {
        console.log(
          `[CCP - On Ended: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
        props.queueUpdate();
        props.contactAttributesUpdate();
        props.updateOutboundFeature(false);
      });
      contact.onMissed((contact) => {
        console.log(
          `[CCP - On Missed: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
        props.updateOutboundFeature(false);
      });
      contact.onDestroy((contact) => {
        console.log(
          `[CCP - On Destroyed: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );

        props.updateOutboundFeature(true);
      });
      contact.onACW((contact) => {
        console.log(
          `[CCP - On After Call Work: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
      contact.onError((contact) => {
        console.log(
          `[CCP - On Error: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(contact)}`
        );
      });
    });

    // eslint-disable-next-line no-undef
    connect.agent((agent) => {
      let agentRoutingProfiles = agent.getRoutingProfile();

      let queues = agentRoutingProfiles.queues.map((x) => {
        return {
          name: x.name,
          value: x.queueARN,
        };
      });
      let filteredQueue = queues.filter((x) => x.name?.includes("voice queue"));
      filteredQueue = filteredQueue.sort((a, b) => (a.name > b.name ? 1 : -1));
      console.log(
        `Agent Routing Profiles: ${moment
          .tz(new Date(), process.env.REACT_APP_TIMEZONE)
          .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
          filteredQueue
        )}`
      );
      filteredQueue = [
        { value: "", name: "--Please Select--" },
        ...filteredQueue,
      ];
      props.updateAgentQueues(filteredQueue);

      agent.onRefresh((agent) => {
        // console.log(`[CCP Agent On Refresh: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
        console.log(
          `[CCP Agent On Refresh: ${moment
            .tz(new Date(), process.env.REACT_APP_TIMEZONE)
            .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
            agent.getState()
          )}`
        );
        // console.log(`[CCP Agent On Refresh: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent.getName())}`)

        props.agentInfo({
          agentState: {
            ...agent.getState(),
          },
          agentName: agent.getName(),
        });

        // console.log(
        //   `[CCP Agent On Refresh: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format(
        //       "YYYY-MM-DD HH:mm:ss"
        //     )}] =====> Agent State: ${JSON.stringify(agent.getState())}}`
        // );

        if (agent.getState().name === "MissedCallAgent") {
          let offlineState = agent
            .getAgentStates()
            .filter((state) => state.name === "Offline")[0];

          setTimeout(() => {
            console.log(
              `[CCP Agent On Refresh: ${moment
                .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                .format("YYYY-MM-DD HH:mm:ss")}] =====> Function executed!}`
            );

            agent.setState(
              offlineState,
              {
                success: function () {
                  console.log(
                    `[CCP Agent On Refresh: ${moment
                      .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                      .format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}] =====> State Changed Successfully!}`
                  );
                },
                failure: function (err) {
                  console.log(
                    `[CCP Agent On Refresh: ${moment
                      .tz(new Date(), process.env.REACT_APP_TIMEZONE)
                      .format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}] =====> Error in State Change ${err.message}!}`
                  );
                },
              },
              { enqueueNextState: false }
            );
          }, 5000);
        }
      });
      agent.onStateChange((agentStateChange) => {
        // console.log(
        //   `[CCP - Agent On State Changed: ${moment
        //     .tz(new Date(), process.env.REACT_APP_TIMEZONE)
        //     .format("YYYY-MM-DD HH:mm:ss")}] =====> ${JSON.stringify(
        //     agentStateChange
        //   )}`
        // );
      });
      agent.onRoutable((agent) => {
        //console.log(`[CCP - Agent On Routable: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onNotRoutable((agent) => {
        //console.log(`[CCP - Agent On Not Routable: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onOffline((agent) => {
        //console.log(`[CCP - Agent On Offline: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onError((agent) => {
        //console.log(`[CCP - Agent On Error: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onSoftphoneError((error) => {
        //console.log(`[CCP - Agent On Softphone Error: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(error)}`)
      });
      agent.onWebSocketConnectionLost((agent) => {
        //console.log(`[CCP - Agent On Connection Lost: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onWebSocketConnectionGained((agent) => {
        //console.log(`[CCP - Agent On WebSocket Connection Gained: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onAfterCallWork((agent) => {
        //console.log(`[CCP - Agent On ACW: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(agent)}`)
      });
      agent.onMuteToggle((obj) => {
        //console.log(`[CCP - Agent On Mute Toggle: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onSpeakerDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Speaker Device Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onMicrophoneDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Microphone Device Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
      agent.onRingerDeviceChanged((obj) => {
        //console.log(`[CCP - Agent On Ringer Changed: ${moment.tz(new Date(), process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD HH:mm:ss')}] =====> ${JSON.stringify(obj)}`)
      });
    });
  }, [props.outbound, props.instance]);

  return <div id="ccp_panel" className={`${styles.ccp_Panel}`}></div>;
};

export default React.memo(CCPPanel);
